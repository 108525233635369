.top-row1,
.top-row2,
.top-row3,
.top-row4 {
    padding-top: 2rem;
    // padding-bottom: 4rem;    
}

.top-row1 {
    background-color: $light-blue;
}

.top-row2 {
    background-color: $light-sand;
}

.top-row3 {
    background-color: $light-blue;
}

.top-row4 {
    background-color: $white;
}

#hero {
    position: relative;

    .yaguchi-wrapper {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
        width: 100%;

        @include breakpoint (medium down) {
            padding-left: 0;
        }

        @include breakpoint (small only) {
            justify-content: center;
            // align-items: flex-start;
        }

        img {
            max-width: 600px;
            z-index: 10;

            @include breakpoint (medium down) {
                width: 80%;
            }

            @include breakpoint (small only) {
                max-height: 280px;
                object-fit: contain;
                font-family: 'object-fit: contain;';
            }
        }
    }
}

#home {
    margin: 0;
    padding: 0;

    h2 {
        border: none;
        font-weight: bold;
        text-align: center;
        color: $cadet-blue;
        padding: 0;
        margin: 0 0 4rem 0;

        @include breakpoint (small only) {
            margin-bottom: 2rem;
            font-size: 1.5rem;
        }
    }

    #event-wrapper h2 {
        margin-top: 0;

        @include breakpoint (medium down) {
            margin-top: 2rem;
        }
    }

    .pickup-contents-card {
        margin-top: 3rem;
    }

    .about {
        padding-bottom: 4rem;

        .callout {
            border-radius: 15px;

            h3 {
                border: none;
                text-align: center;
                font-weight: bold;
                color: $cadet-blue;
            }
        }

        @include breakpoint (large) {
            background-image: url(#{$path}assets/img/common/img-char07.png);
            background-repeat: no-repeat;
            background-position: 98% bottom;
            background-size: 200px;
        }
    }
}

#event-wrapper,
.main-section {

    .no-event {
        background-color: $light-sand;
        height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
            border: none;
            text-align: center;
            margin: 0;
        }

        @include breakpoint (small only) {
            height: 280px;
        }
    }
}
#event-wrapper .no-event img {
            width: 360px;

            @include breakpoint (small only) {
                width: 200px;
            }

}
.main-section .no-event img {
            display: inline-block;
            height: 300px;
            object-fit: contain;
            font-family: 'object-fit: contain;';
            @include breakpoint (small only) {
                height: 180px;
            }

}
