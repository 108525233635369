// Top Hero Slider
#hero {
    .slick-container {
        position: relative;
        .slick-carousel-wrapper {
            margin: 0;

            button {
                z-index: 15;
                width: 2rem;
                height: 2rem;

                &:hover {
                    text-decoration: none;
                }

                &:focus {
                    outline: 2.5px solid $blue;
                }

                &::before {
                    color: $cadet-light-blue;
                    font-size: 2rem;
                }

                &.slick-prev {
                    left: 0;
                    margin-left: .25rem;
                }

                &.slick-next {
                    right: 0;
                    margin-right: .25rem;
                }
            }

            .slick-carousel {
                height: 600px;
                    @include breakpoint (small only){
                        height: 280px;
                    }                
                position: relative;
                figure {
                    height: 100%;
                    @include breakpoint (small only){
                        height: 280px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        font-family: 'object-fit: cover;';
                        object-position: 50% 50%;
                        &.slide01 {
                            object-position: bottom;
                        }
                    }
                }

            }
        }

        .slick-btn-wrapper {
            position: relative;

            .slick-btn {
                width: 100%;
                z-index: 5;
                position: absolute;
                top: -100px;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;

                button {
                    margin-top: .5rem;
                    background-color: $cadet-blue-gray;

                    &:hover {
                        background-color: $cadet-blue;
                    }

                    &:first-child {
                        margin-right: .5rem;
                    }
                }
            }
        }
    }
}

// event slider
#event-wrapper {
    padding: 0 3rem;
    .module_carousel {

        .single-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .slick-prev:before, .slick-next:before {
            color: $cadet-dark-blue;
            font-size: 30px;
        }
        .slick-prev {
            left: -36px;
        }
        .slickPanel {
            background-color: $white;
            @include shadow($prototype-box-shadow);
            margin: 0 2rem;
            border-radius: 10px;
            padding: 1rem;
            width: 480px;

            @include breakpoint (small only) {
                margin: 0 .5rem;
                padding: .5rem;
            }

            .media-object {
                .media-object-section {
                    h3 {
                        border: none;
                        margin: 0 0 1rem 0;
                        font-size: 1.4rem;
                    }

                    a {
                        padding-left: 0;

                        &:focus img {
                            border: 5px solid $blue;
                        }
                    }
                }
            }
        }

        .slick_controller {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            button {
                padding: .2rem .7rem;
                background-color: $cadet-blue;
                margin-bottom: .5rem;

                &:hover {
                    background-color: darken($color: $gray, $amount: 30%);
                }

                &:first-child {
                    margin-right: .5rem;
                }

                i {
                    // font-size: 1rem;
                    color: $white;
                }
            }
        }
    }
}

// yaguchi-slider
#yaguchi_slick-wrapper {
    .slickPanel {
        background-color: $powder-blue-light;
        margin: 0 1.5rem;
        border-radius: 15px;
        padding: 1rem;
        height: 300px;

        @include breakpoint (small only) {
            height: 100%;
        }

        h3 {
            border: none;
            font-size: 1.2rem;
            text-align: center;
            margin: 0;
            font-weight: bold;
        }

        .media-object {
            .media-object-section {
                img {
                    padding: .5rem;
                    height: 240px;
                    object-fit: contain;
                    font-family: 'object-fit: contain;';
                }
            }
        }
    }

    .slick_controller {
        display: flex;
        justify-content: flex-end;

        button {
            padding: 0.2rem 0.7rem;
            margin: .5rem;
        }
    }
}