.sideNav {
    @include breakpoint(large) {
        list-style-type: none;
        border: 1px solid $gray;
        @include shadow($prototype-box-shadow);

        >li {
            font-size: 1.25rem;
            border-bottom: 1px solid $gray;
            border-left: 2rem solid $main-blue01;
            position: relative;
            &.current>a {
                font-weight: bold;
                background-color: $cadet-light-blue;
            }

            &.current::before {
                position: absolute;
                content: "\f105";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                top: .5rem;
                left: -1.25rem;
                color: $yellow;
            }

            &.current:hover:before {
                left: -1rem;
                transition-duration: 0.5s;
            }

            &:last-of-type {
                border-bottom: none;
            }

            a {
                text-decoration: none;
                color: $black;
                background-color: $light-gray;
                display: block;

                &:hover {
                    background-color: $marin-blue;
                }
            }

            button.submenu-toggle {
                background-color: $light-gray;

                &::after {
                    border-top-color: $dark-green;
                }
            }

            .nested {
                margin-left: 0 !important;

                li {
                    border-top: 1px solid $gray;
                    padding-left: 1rem;

                    &:hover {
                        background-color: $marin-blue;
                    }

                    &.current {
                        background-color: $cadet-light-blue;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: "\f105";
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            top: .5rem;
                            left: -1.25rem;
                            color: $yellow;
                        }

                        &>a {
                            font-weight: bold;
                            background-color: transparent;
                        }
                    }

                }
            }
        }
    }

    @include breakpoint(medium down) {
        margin-bottom: 1rem;
        background-color: $white;
        border-left: 20px solid $main-blue01;

        li {
            padding: 0.5rem 1rem;
            line-height: 1.2;
            position: relative;
            font-size: 1.2rem;

            &::after {
                position: absolute;
                content: "|";
                right: 0;
            }

            &:last-of-type::after {
                content: none;
            }

            a {
                padding: 0;
                line-height: 1.2;
                text-decoration: underline;
            }
        }
    }

}