.banner-list{
    list-style: none;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a {
            text-decoration: none;
            padding: .5rem;
            &:hover {
                img {
                    filter: opacity(70%);
                }
            }
            img {
                display: inline-block;
                aspect-ratio: 4 / 3;
                object-fit: cover;
                font-family: 'object-fit: cover;';
                @include shadow($prototype-box-shadow);
            }
            .banner-section {
                text-align: center;
            }
        }
        &:first-of-type {
            img {
                padding: .5rem;
                object-fit: contain;
                font-family: 'object-fit: contain;';
            }
        }
    }
}