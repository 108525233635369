header {
    background-color: $white;
    position: relative;
    z-index: 20;
    .pc-headerNav {
        // z-index: 3;
        .header-title {
            .title-wrapper {
                padding: .5rem 1rem;
                background-color: $white;
                .logo-pc {
                    h1 {
                        background-color: transparent;
                        margin-bottom: 0;
                        img {
                            width: 200px;
                        }
                    }
                }
                .subNav {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    .sub-menu {
                        li {
                            border-right: 1px solid $cadet-light-blue;
                            // max-width: 120px;

                            &:last-of-type {
                                border-right: none;
                            }

                            a {
                                font-size: 90%;
                                text-decoration: none!important;
                                span {
                                    text-decoration: none!important;
                                }
                                .has-tip {
                                    border-bottom: none;
                                }


                                i {
                                    display: block;
                                    text-align: center;
                                    font-size: 1.25rem;
                                    margin-bottom: .25rem;
                                }

                                span {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}