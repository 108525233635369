// SP title bar
.title-bar.sp-title-bar {
    background-color: $cadet-blue;
    height: 3.5rem;
    .logo {
        margin-bottom: 0;
        img {
            height: 3rem;
            object-fit: contain;
            font-family: 'object-fit: contain;';
        }
    }
}
// SP  off-canvas menu
#offCanvas {
    background-color: $cadet-light-blue;
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: left top;
    background-blend-mode:luminosity;
    .offcanvas-logo-wrapper {
        margin-top: 3rem;
        img {
            object-fit: contain;
            font-family: 'object-fit: contain;';
        }
    }
    nav {
        > ul {
            > li {
                border-top: 1px solid $gray;
                &:last-of-type {
                    border-bottom: 1px solid $gray;
                }
                ul.nested {
                    li {
                        border-top: 1px solid $gray;
                    }
                }

            }
        }
    }
    .subNav {
        padding-top: 1.5rem;
        .google-search-form-wrapper {
            padding: 0 1rem;
        }
        .sub-menu {
            li {
                a {
                    // text-decoration: underline;
                    i {
                        display: none;
                    }
                }
            }
        }
    }
}