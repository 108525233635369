// font-family: "Font Awesome 5 Free";
// font-weight: 900;
.link-to {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    li {
        position: relative;
        padding-left: 1em;
        margin-right: 1em;

        &::before {
            position: absolute;
            content: "\f101";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: $dark-blue;
            left: 0;
            font-size: inherit;
        }

        a {
            font-weight: bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

// dl list 
dl {

    dt,
    dd {
        margin-bottom: .5em;
    }
    dt {
        font-weight: normal;
    }
    dd {
        margin-left: 1em;
    }

    &.horizontal {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include breakpoint (medium down) {
            flex-direction: column;
        }

        dt {
            width: 50%;

            @include breakpoint (medium down) {
                width: 100%;
            }
        }

        dd {
            width: 50%;
            margin-left: 0;

            @include breakpoint (medium down) {
                margin-left: 1rem;
                width: 100%;
            }
        }
    }
}

// link list 
.link-list {
    list-style: none;
    margin-left: 1.5rem;

    li {
        position: relative;
        font-size: 1.2rem;
        margin-bottom: .5rem;

        &::before {
            position: absolute;
            display: inline-block;
            top: 0;
            left: -1.25rem;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f138";
            color: $main-blue01;

        }
    }

    &.lead {
        li {
            &::before {
                left: -1.5rem;
            }
        }
    }
}
// index link list 
.index-link-list {
    list-style: none;
    margin-left: 1.5rem;

    li {
        position: relative;
        font-size: 1.2rem;
        margin-bottom: .5rem;

        &::before {
            position: absolute;
            display: inline-block;
            top: 0;
            left: -1.25rem;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f150";
            color: $main-blue02;

        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.lead {
        li {
            &::before {
                left: -1.5rem;
            }
        }
    }
}

.no-list {
    list-style: none;
}

// 注釈
.caution {
    list-style: none;
    padding: 0 1rem;

    li {
        position: relative;

        &::before {
            position: absolute;
            content: '※';
            color: $red;
            left: -1rem;
            top: 0;
        }
    }
}

.chu-shaku {
    list-style: none;
    padding: 0 1rem;

    li {
        margin-left: 3em;
        position: relative;

        &::before {
            position: absolute;
            content: "（注）";
            color: $red;
            left: -3em;
            top: 0;
        }
    }
}

// 装飾
.dia-list {
    list-style: none;
    padding-left: 2.5rem;

    li {
        position: relative;

        &::before {
            position: absolute;
            content: "\25c6";
            left: -1.5rem;
        }
    }

}

// 目次
.index-numbers {
    font-size: 1.5rem;
    margin-left: 5rem;

    &.lv1>li {
        margin-bottom: 2rem;

        .lv2>li {
            list-style-type: none;
            list-style-position: inside;
            counter-increment: cnt;

            &::before {
                display: marker;
                content: "（"counter(cnt) "）";
            }

        }
    }

    .lv3 {
        // margin-left: 2rem;
        list-style: none;
        text-indent: -1.25em;

        span {
            margin-right: .25rem;
        }
    }
}

.indent-2em {
    list-style: none;
    text-indent: -2em;
    margin-left: 2.5em;

    span {
        margin-right: .5em;
    }

}

.indent-4em {
    list-style: none;
    text-indent: -4em;
    margin-left: 4.5em;

    span {
        margin-right: .5em;
    }

}

.step2step {
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 5rem auto;

    >li {
        background-color: $light-yellow;
        @include shadow($prototype-box-shadow);
        border-radius: 15px;
        padding: 2rem;
        margin-bottom: 5rem;
        width: 90%;
        position: relative;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include breakpoint(medium down) {
            width: 100%;
        }

        &::after {
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f063";
            color: $cadet-blue;
            left: 50%;
            font-size: 4rem;
            bottom: -5.5rem;
        }

        &:last-of-type::after {
            content: none;
        }

        .media-object {
            margin-bottom: 0;

            .button-wrapper {
                text-align: center;
            }

            p {
                padding: 0;
                font-size: 1.5rem;
            }

            dl {

                dt,
                dd {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

// 連番リスト装飾
.roman-s {
    list-style: lower-roman;
}

.roman-l {
    list-style: upper-roman;
}

// サイトマップ
.sitemap-wrapper {
    height: 480px;

    .menu.vertical {
        height: 100%;
        flex-wrap: wrap !important;

        &.parent>li {
            font-weight: bold;

            .nested {
                margin-left: 2rem;

                li {
                    font-weight: normal;
                }
            }
        }
    }
}

// Vertical Menu Nested Z-index
.vertical.menu.parent {
    >li {
        z-index: 5;
    }
}

// カッコ数字
.list-bracket-num {
    list-style: none;

    li {
        list-style-position: inside;
        counter-increment: cnt;
        text-indent: -1.8em;

        &::before {
            display: -moz-marker;
            display: marker;
            content: "（"counter(cnt) "）";
        }
    }
}

.dot-list-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    dt {
        width: 20%;
        position: relative;

        @include breakpoint(medium down) {
            width: 100%;
        }

        &::before {
            position: absolute;
            margin-right: .25rem;
            display: block;
            content: "";
            width: 1rem;
            height: 1rem;
            background-color: $cadet-blue;
            border-radius: 50%;
            top: .25rem;
            left: -1.25rem;
        }
    }

    dd {
        width: 80%;
        margin-left: 0;

        @include breakpoint(medium down) {
            width: 100%;
            margin-left: 2rem;
        }
    }
}
// image list
.img-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    margin-left: 0;
    @include breakpoint (small only){
        flex-wrap: wrap;
    }
    li {
        padding: .5rem;
        display: inline-block;
    @include breakpoint (small only){
        width: 100%;
    }
        img {
            // aspect-ratio: 4 / 3;
            object-fit: contain;
            font-family: 'object-fit: contain;';
            max-height: 300px;
            width: auto;
            display: block;
        }
    }
}