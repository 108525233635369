.title-header {
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    #event &
     {
    background-image: url(#{$path}assets/img/common/title-header-event.jpg);
    background-position: center 45%;
    } 
    #education & 
     {
    background-image: url(#{$path}assets/img/common/title-header-education.jpg);
    background-position: center 45%;
    } 
    #advisor &  {
    background-image: url(#{$path}assets/img/common/title-header-advisor.jpg);
    background-position: center 60%;
    } 
    #info-link &  {
    background-image: url(#{$path}assets/img/common/title-header-info.jpg);
    background-position: center 70%;
    } 
    #puzzle &  {
    background-image: url(#{$path}assets/img/common/title-header-puzzle.jpg);
    background-position: center 70%;
    } 
    #contact &  {
    background-image: url(#{$path}assets/img/common/title-header-contact.jpg);
    background-position: center 70%;
    } 
    #privacy &  {
    background-image: url(#{$path}assets/img/common/title-header-privacy.jpg);
    background-position: center 70%;
    } 
    #accessibility &  {
    background-image: url(#{$path}assets/img/common/title-header-accessibility.jpg);
    background-position: center 70%;
    } 
    #manga &  {
    background-image: url(#{$path}assets/img/common/title-header-manga.jpg);
    background-position: center 70%;
    } 
    #news &  {
    background-image: url(#{$path}assets/img/common/title-header-news.jpg);
    background-position: center 60%;
    } 

    h1 {
        text-align: center;
        background-color: transparent;
        color: $black;
        font-weight: bold;
        background-color: rgba($color: $white, $alpha: .8);
        border-radius: 15px;
        width: 60%;

        @include breakpoint (medium down) {
            width: 90%;
        }

        position: relative;
        padding-top: .25em;
        padding-bottom: .25em;

        &::before,
        &::after {
            position: absolute;
            content: "";
        }

        &::before {
            width: 80px;
            height: 80px;
            background-size: contain;
            background-repeat: no-repeat;
            left: 0;
            top: -25%;
            background-image:url(#{$path}assets/img/common/title-header-char01.png);

            // @include breakpoint (small only) {
            //     background-image:url(#{$path}assets/img/common/title-header-char01-sp.png);
            //     width: 50px;
            //     height: 50px;
            // }
        }

        &::after {
            width: 80px;
            height: 80px;
            background-size: contain;
            background-repeat: no-repeat;
            right: 0;
            top: 20%;
            background-image:url(#{$path}assets/img/common/title-header-char02.png);

            // @include breakpoint (small only) {
            //     background-image:url(#{$path}assets/img/common/title-header-char02-sp.png);
            //     width: 50px;
            //     height: 50px;
            // }
        }
    }
}