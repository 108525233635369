
    .main-section {

        h2 {
            padding: .5em;
            background-color: $cadet-blue;
            // margin-top: 2rem;
            margin-bottom: 3rem;
            // border-radius: 15px;
            color: $white;
            font-weight: bold;
            border-left: 30px double $white;
            // border-right: 20px solid $cadet-blue;
            @include shadow($prototype-box-shadow);
            &:not(:first-of-type){
                margin-top: 2.5rem;
            }
            @include breakpoint (small only) {
                margin-bottom: 1rem;
            }
        }

        h3 {
            border-left: 20px solid $cadet-blue;
            border-bottom: 4px solid $cadet-blue;
            padding-left: .5rem;
            // margin-left: 1.5em;
            margin-top: 2rem;
            margin-bottom: 2rem;

            span.sub {
                font-size: 60%;
            }
        }

        h4 {
            margin-left: 2rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                width: 20px;
                height: 20px;
                background-color: $cadet-blue;
                left: -1.5rem;
                top: .5rem;
            }
        }

        p {
            line-height: 1.7;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        ol,
        ul,
        dl
         {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .no-deco {
        border: none;
        background-color: transparent;
    }

    .jumbo {
        font-size: 200%;
    }