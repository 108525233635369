.delighter-slide-up.delighter {
    transition: all .5s ease-out;
    transform: translateY(50%);;
    opacity: 0;
    &.started {
        transform: none;
        opacity: 1;
    }
}
.delighter-slide-l-r.delighter {
    transition: all .5s ease-out;
    transform: translateX(-100%);;
    opacity: 0;
    &.started {
        transform: none;
        opacity: 1;
    }
}
.delighter-slide-r-l.delighter {
    transition: all .5s ease-out;
    transform: translateX(100%);;
    opacity: 0;
    &.started {
        transform: none;
        opacity: 1;
    }
}