footer {
    padding: 0;
    background-color: $sand;
p {
    margin-bottom: 0;
}
    hr {
        max-width: 100%;
        border-bottom-color: $gray;
    }

    .grid-container {
        a {
            display: inline-block;
        }
        .footer-sitemap {
            >.menu {



                >li {
                    width: 300px;
                    a {
                        // text-decoration: underline;
                        font-size: 1.1rem;
                        padding: 0.5rem 0.7rem;
                        // color: $smoke;
                        color: $main-blue01;
                        font-weight: bold;

                        &:hover {
                            color: $blue;
                        }
                    }

                    // >a {
                    //     font-weight: bold;

                    // }

                    >.menu {
                        padding-left: 1rem;

                    }
                }

            }
        }

        .company-info {
            display: flex;
            @include breakpoint(large) {
            justify-content: flex-end;
            }

            address {
                color: $black;
            }
        }

        .footer-map {
            iframe {
                width: 100%;
                aspect-ratio: 16 / 9;
            }

            a {
                text-decoration: underline;
                color: $powder-blue-light;
                font-size: 1.2;
            }

            p {
                color: $white;
                margin-bottom: 0;
            }

            ul {
                li {
                    color: $white;
                }
            }
        }
    }
}