.gNav-wrapper {
    background-color: $cadet-dark-blue;

    nav {
        z-index: 25;
       > ul {
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            li {
                width: 100%;

                a {
                    color: $white;
                    width: 100%;
                    text-align: center;
                    line-height: 1.6;
                    &:hover {
                        font-weight: bold;
                    }
                }

                ul.menu {
                    border-right: 1px solid $dark-gray;
                    border-bottom: 1px solid $dark-gray;
                    border-left: 1px solid $dark-gray;
                    li {
                        border-bottom: 1px solid $gray;
                        &:hover {
                            background-color:$marin-blue;
                        }
                        &:last-of-type {
                            border-bottom: none;
                        }
                        a {
                            color: $black;
                            &::after {
                                border-left-color: $main-blue01!important;
                            }
                            &:hover::after {
                                border-left-color: $yellow!important;
                            }
                        }
                    }
                }
            }
        }
    }
}