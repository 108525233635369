.tab-table-wrapper {
    ul {
        border: none;
        padding-left: 0;
        li {
            border-top: 1px solid $gray;
            border-right: 1px solid $gray;

            &:first-of-type {
                border-left: 1px solid $gray;
            }

            &.is-active {
                border-bottom: 3px solid $powder-blue-light;

                a {
                    background-color: $main-blue01;
                    color: $white;

                    &:hover {
                        background-color: $main-blue01;
                        font-weight: bold;
                        color: $white;

                    }
                }
            }

            a {
                font-size: 1.2rem;
                text-decoration: none;
                background-color: $light-gray;
                color: $main-blue01;
                &:hover {
                    background-color: $gainsboro;
                    color: $black;
                }
            }
        }
    }

    .tabs-panel {
        padding: 0;

        table {
            box-shadow: none;
            margin-bottom: 0;

            thead {
                tr {
                    background-color: $main-blue01;

                    th {
                        text-align: center;
                        color: $white;
                    }
                }
            }

            tbody {
                border: none;
            }
        }
    }

}