.breadcrumbs-nav {
    padding:0.5rem 3rem;
    background-color: $white;
    .breadcrumbs {
        margin-bottom: 0;
        li {
            font-size: 1.2rem;
            // a {
            //     background-color: rgba($color: $white, $alpha: .85);
            //     padding: .5rem;
            // }
        }
    }  
}