section {
    .scroll {
        overflow-x: scroll;
        overflow-y: scroll;

        iframe {
            width: 100%;
            min-width: 600px;
            min-height: 400px;
        }
    }
}