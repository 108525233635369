#home {
    .pickup-contents-card {
        a {
            text-decoration: none;

            .card {
                transition: 0.2s all ease 0s;

                &:hover {
                    transform: translate3d(0, 3px, 0);
                    background-color: $light-gray;
                }

                border-radius: 15px;
                justify-content: space-between;
                @include shadow($prototype-box-shadow);

                .card-header {
                    padding: .5rem;

                    h3 {
                        font-size: 1.35rem;
                        font-weight: bold;
                        color: $cadet-blue;
                        border: none;
                        margin: 0;
                        padding-left: 0;
                        text-align: center;
                    }
                }

                .card-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        object-fit: contain;
                        font-family: 'object-fit: contain;';
                    }
                }

                hr {
                    border: 1px solid $gainsboro;
                    width: 90%;
                }

                .card-section {
                    padding-left: .5rem;
                    padding-right: .5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

            &.pc2 {
                img {
                    min-height: 200px;
                }
            }
            }
        }
    }
}