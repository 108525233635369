.callout {
    @include shadow($prototype-box-shadow);
    padding-bottom: 5rem;
}
// top
.activity {
    .callout {
        border-radius: 15px;
        padding-bottom: 1rem;
        h3 {
            border: none;
            font-weight: bold;
            color: $cadet-blue;
            margin: 0 0 1rem 0;
            font-size: 1.5rem;
        }
        img {
            max-height: 180px;
            object-fit: contain;
            font-family: 'object-fit: contain;';
        }
    }
}
