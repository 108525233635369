img {
    width: 100%;
}

.float-left,
.float-right {
    margin: .5rem;
}

// manga
#manga {
    .comic-wrapper {
        text-align: center;
        img {
            max-width: 600px;
        }
    }
}