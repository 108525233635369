// typography setting
.main-section table {

    th,
    td {

        p,
        ul,
        ol,
        dl {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// top 
.about {
    table {
        caption {
            text-align: center;
            background-color: $cadet;
            color: $white;
        }

        tr {
            background-color: $white;

            th,
            td {
                border: 1px solid $gray;
            }

            th {
                width: 25%;
                text-align: left;
            }
        }
    }
}

// event
#event {
    table {
        width: 90%;
        margin-left: 2rem;
        @include shadow($prototype-box-shadow);

        thead {
            tr {
                background-color: $mint-blue;

                th {
                    text-align: center;
                    border-right: 1px solid $light-gray;

                    &:last-of-type {
                        border-right: none;
                    }
                }
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: lighten($color: $cadet-light-blue, $amount: 10);
                }

                td {
                    &:first-of-type {
                        border-right: 1px solid $gray;
                    }
                }
            }
        }
    }
}

// advisor
#advisor {
    table {
        width: 90%;
        margin-left: 2rem;

        caption {
            background-color: $mint-blue;
            text-align: center;
            color: $black;
        }

        tbody {
            border: 5px solid $mint-blue;

            tr {
                border-bottom: 1px solid $mint-blue;

                &:last-of-type {
                    border-bottom: none;
                }

                &:nth-child(even) {
                    background-color: $white;
                }

                th {
                    background-color: $light-gray;
                    font-weight: bold;
                    text-align: left;
                    width: 30%;
                }

            }
        }

        &.data-list {
            tbody {
                td {
                    text-align: center;
                }
            }
        }

        &.advisor-list {

            th,
            td {
                ul {
                    padding-left: 1rem;
                }
            }

            th {
                width: 40%;
            }

            td {
                h3 {
                    font-size: 1.2rem;
                    border: none;
                    color: $dark-blue;
                    font-weight: bold;
                    margin: .5rem;
                }
            }
        }
    }
}

.tbl-row {
    tbody {
        tr {
            background-color: transparent;
            border: 1px solid $dark-gray;

            th {
                background-color: $cadet-blue;
                vertical-align: middle;
                color: $white;
            }
        }
    }
}

// event
#event {
    table {
        thead {
            tr {
                th {
                    &:first-of-type {
                        width: 25%;
                    }
                }
            }
        }
        tbody {
            tr {
                border: 1px solid $gray;
                &:nth-child(even){
                    background-color: transparent;
                }
                th {
                    background-color: $light-gray;
                    text-align: left;
                    width: 20%;
                }
            }
        }
    }
}