$white : #fefefe;
$light-gray : #f5f5f5;
$gainsboro : #dcdcdc;
$gray : #c0c0c0;
$dark-gray : #545454;
$black : #080808;
$dark-green : #006060;
$dark-blue : #1460aa; 
$blue : #0000e0;
$main-blue01 : #13448f;
$main-blue02: #4269a5;
$light-blue : #DCF2FF;
$mint-blue : #84ccc9;
$light-yellow : #FEF7DC;
$khaki : #F0DBC0;
$dark-khaki : #A3927A;
$sand : #FDF1CF;
$light-sand: #FFFBEF;
$smoke : #eff4f6;
$cadet : #417D86;
$cadet-blue : #027EAB;
$cadet-dark-blue :#015878; 
$cadet-blue-gray : #606B85;
$cadet-light-blue-gray : #a6aebf;
$powder-blue-light :#DEEFEF;
$cadet-light-blue : #c6e0ec;
$marin-blue : #d4edf7;
$red : #d00000;
$yellow : #ffff00; 
$orange : #FFA500;
$green : #00552a;
.red {
    color: $red;
}
.blue {
    color: $blue;
}
.green {
    color: $dark-green;
}
.bg-lightblue {
    background-color: $light-blue;
}