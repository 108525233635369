.video-wrapper {
    position: relative;
    width: 100%;
    &::after {
        content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba($color: $black, $alpha: .4);
    }
     video {
        object-fit: cover;
        font-family: 'object-fit: cover;';
        min-width: 100%;
        min-height: 100%;
    }
}