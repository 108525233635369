.kengaku-naiyou {
    padding: 2rem;

    .media-object {
        padding-bottom: 1rem;

        .media-object-section {
            h3 {
                border: none;
                font-size: 1.35rem;
                margin: 0 .25rem .5rem .25rem;
            }
        }
    }
}

.annai-steplist {
    margin-top: 10rem;
    margin-bottom: 5rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .arrow {
        margin-top: 5rem;
        margin-bottom: 5rem;

        img {
            max-width: 150px;
        }
    }
}

#event-template {
    .media-object {
        align-items: flex-start;
        .media-object-section {
            .menu {
                justify-content: center;
                li {
                    padding: 0 .25rem;
                }
            }
            img {
                width: 100%;
                object-fit: contain;
                font-family: 'object-fit: contain;';
            }
            .link-list {
                margin-left: 0;
                padding-right: 0;
            }
        }
    }
}