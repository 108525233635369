.icon-inline {
    display: inline-block;
    width: 2rem;
    margin-right: .5rem;

}

// pdfファイルの拡張子別アイコン生成
a[href$=".pdf"],
a[href$=".xls"],
a[href$=".xlsx"],
a[href$=".doc"],
a[href$=".docx"]  {
    position: relative;
    &::before {
        position: absolute;
        font-family: $font-icon;
        font-weight: 900;
        left: 0;
    }

    p &,
    li &,
    td & {
        padding-left: 1rem;
    }
    li span & {
        &::before {
            left: 1.5rem;
        }
    }
}

a[href$=".pdf"]::before {

    content: $pdf;

    color: $red;
}

a[href$=".docx"]::before {
    content: $word;
    color: $blue;
}
a[href$=".doc"]::before {
    content: $word;
    color: $blue;
}

a[href$=".xlsx"]::before {
    content: $excel;
    color: $green;
}
a[href$=".xls"]::before {
    content: $excel;
    color: $green;
}