@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

body {
  overflow-x: hidden;
    background-color: $light-blue;

  a {
    color: $dark-blue;
    text-decoration: underline;
  }
}