.main-wrapper {
   .main-section {
        background-color: $white;
        @include shadow($prototype-box-shadow);
        padding: 2rem;
        margin-top: 3rem;
        margin-bottom: 5rem;
        > section {
            padding: .5rem;
        }
    }
}
// ページ毎
// puzzle
#puzzle section section {
    margin-bottom: 3rem;
}