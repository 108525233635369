#home {
    .whats-new {
        background-color: $white;
            border-radius: 15px;
        .whats-new-header {
            border-radius: 15px 15px 0 0;
            background-color: $cadet-blue;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                width: 72px;
            }
            h2 {
                border: none;
                color: $white;
                font-size: 1.6rem;
                font-weight: bold;
                text-align: center;
                padding: .5rem;
                margin: 0;
            }
        }

        dl {
            max-height: 480px;
            overflow-y: scroll;
            padding: 1rem .25rem;

            dt {
                padding-left: 1rem;
                padding-right: 1rem;
                a {
                    text-decoration: none;
                }
            }

            dd {
                text-align: right;
                border-bottom: 1px solid $dark-gray;
                margin-bottom: 1rem;
            }
        }

        .whats-new-footer {
            background-color: $cadet-blue;
            padding: .5rem .5rem 1rem .5rem;
            border-radius: 0 0 15px 15px;
            p {
                margin: 0;
                a {
                    color: $white;
                }
            }
        }
    }
}